<template>
  <div>
    <table>
      <tr>
        <th colspan="2">
          <img v-bind:src="season.image"/>
            <button v-if="management" class="red float-end" @dblclick="deleteSeason">×</button>
          <input v-model="season.image" v-if="management" size="45"/>
          <h2>Season {{ season.season }}</h2>
        </th>
      </tr>
      <tr>
        <td>
          Watches:&emsp;
        </td>
        <td>
          <!-- if $store.state.auth button to increment and decrement -->
          {{ season.stats.counter }}
          <span v-if="management">
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            <button v-on:click="increment">+</button>
            &ensp;
            <button v-on:click="decrement">-</button>
          </span>
        </td>
      </tr>
      <tr>
        <td>
          Status:
        </td>
        <td v-if="management">
          <!-- Textboxarea wide and high -->
          <textarea v-model="season.stats.status" rows="6" cols="34"></textarea>
        </td>
        <td v-else>
          {{ season.stats.status }}
        </td>
      </tr>
      <!-- Button to save -->
      <tr v-if="management">
        <td colspan="2">
          <button class="button-wide" v-on:click="save" @dblclick="saveExit">Save</button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    data: Object,
    management: Boolean
  },
  created() {
    this.season = this.data;
  },
  methods: {
    increment() {
      axios.put(`/count/increment/${this.data.season}`, {}, {
        withCredentials: true
      })
      .then(() => {
        this.season.stats.counter++;
      })
      .catch(error => {
        console.error(error);
      });
    },
    decrement() {
      axios.put(`/count/decrement/${this.data.season}`, {}, {
        withCredentials: true
      })
      .then(() => {
        this.season.stats.counter--;
      })
      .catch(error => {
        console.error(error);
      });
    },
    save() {
      axios.put(`/update`, this.season, {
        withCredentials: true
      })
      .then(() => {
      })
      .catch(error => {
        console.error(error);
      });
    },
    saveExit() {
      this.save();
      this.$router.push('/');
    },
    deleteSeason() {
      axios.delete(`/season/${this.data.season}`, {
        withCredentials: true
      })
      .then(() => {
        // destroy the component
        // this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      })
    }
  },
  data() {
    return {
      season: Object,
      toast: Object
    };
  }
}
</script>

<style scoped>
table {
  width: 389px;
  margin: auto;
  text-align: left;
  background-color: #0000005e;
  border-radius: 1em;
  padding: 1em;
}
tr {
  vertical-align: top;
}
img {
  width: 351px;
  height: auto;
  border-radius: 1em;
  z-index: 1;
}
textarea, input {
  /* transparent background */
  background-color: rgba(255, 255, 255, 0.178);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  border-color: transparent;
}
button {
  /* round */
  border-radius: 1em;
  background-color: green;
  border-color: transparent;
  height: 1.75em;
  width: 1.75em;
  color: white;
}
.button-wide {
  width: 100%;
}
.rightAlign {
  text-align: right;
}
.red {
  background-color: red;
}
.float-end {  
  position: absolute;
  margin-top: 0.75em;
  margin-left: -2.5em;
  
  z-index: 999;
}
</style>
