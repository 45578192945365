<template>
  <div class="home">
    <!-- Loop through seasons -->
    <div class="flexbox">
      <div v-for="season in seasons" :key="season.season">
          <CountDisplay class="item" :data="season"/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CountDisplay from '@/components/CountDisplay.vue'
import axios from 'axios';

export default {
  name: 'HomePage',
  components: {
    CountDisplay
  },
  data() {
    return {
      seasons: [],
    };
  },
  created() {
    axios.get('/data')
    .then(response => {
      this.seasons = response.data;
    })
    .catch(error => {
      console.error(error);
    });
  }
}
</script>

<style scoped>
.flexbox {
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  margin-top: 10em;
}

.flexbox .item:first-child {
  margin-left: auto;
}
.flexbox .item:last-child {
  margin-right: auto;
}

/* Media query screen smaller thatn 820px width */
@media screen and (max-width: 820px) {
  .flexbox {
    margin-top: 0;
    flex-direction: column;
  }
}

</style>

