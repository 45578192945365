<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/management">Management</router-link>
  </nav> -->
  <div id="cssmenu">
    <ul>
      <li>
        <router-link to="/">Home</router-link>
      </li>
      <li>  
        <a href="https://www.netflix.com/watch/81210762" target="_blank">watch on netflix</a>
      </li>
      <li>  
        <a href="https://discord.gg/youngroyals" target="_blank">discord</a>
      </li>
      <li>
        <a href="https://reddit.com/r/YoungRoyals" target="_blank">reddit</a>
      </li>
      <li>
        <router-link to="/about">About</router-link>
      </li>
      <li v-if="$store.state.auth">
        <router-link to="/management">Management</router-link>
      </li>
      <li v-if="$store.state.auth">
        <span @click="logout">Logout</span>
      </li>
      <li v-else>
        <router-link to="/login">Login</router-link>
      </li>
    </ul>
  </div>
  <div class="background-image"></div>
  <router-view class="content"/>
</template>

<script>
  export default {
    created() {
      this.$store.state.auth = localStorage.getItem('auth');
    },
    methods: {
      logout() {
        localStorage.clear();
        this.$store.state.auth = false;
        document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        this.$router.push('/');
      }
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}

body {
  background-color: black;
  margin: 0;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.background-image {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
  background-image: url('./assets/kaggeholms.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  -webkit-filter: brightness(50%) blur(7px);
  -moz-filter: brightness(50%) blur(7px);
  -o-filter: brightness(50%) blur(7px);
  -ms-filter: brightness(50%) blur(7px);
  filter: brightness(50%) blur(7px);
}

.content {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9999;
  margin-left: 20px;
  margin-right: 20px;
}

#cssmenu
{
  width:auto;
  display:block;
  text-align:center;
  font-family:Oswald;
  line-height:1.2;
}
#cssmenu ul
{
  width:auto;
  display:block;
  font-size:0;
  text-align:center;
  color:#EEEEEE;
  background-color: #333333;
  border: transparent;
  margin:0; 
  padding:0;
  list-style:none;
  position:relative;
  z-index:999999990;
  border-radius: 3px;
} 

#cssmenu li
{
  display:inline-block;
  position:relative;    
  font-size:0; 
  margin:0;
  padding:0;
}

/*Top level items
---------------------------------------*/

#cssmenu >ul>li>span, #cssmenu >ul>li>a 
{   
  font-size:22px;
  color:inherit;
  text-decoration:none;
  padding:14px 20px; 
  font-weight:400;
  text-transform:uppercase;
  letter-spacing:2px;   
  display:block;   
  position:relative;
  transition:all 0.3s;
}
#cssmenu li:hover > span, #cssmenu li:hover > a
{  
  color:#333333;
  background-color:#F3F3F3;
  border-radius: 1em;
}


@keyframes topItemAnimation {
  from {opacity: 0; transform:translate3d(0, -60px, 0);}
  to {opacity: 1; transform:translate3d(0, 0, 0);}
}
#cssmenu li a {animation:none;}
#cssmenu.active li a { animation: topItemAnimation 0.5s cubic-bezier(.16,.84,.44,1) 0.08333333333333333s backwards;}
#cssmenu.active li:nth-of-type(1) a { animation: topItemAnimation 0.5s cubic-bezier(.16,.84,.44,1) 0.5s backwards;}
#cssmenu.active li:nth-of-type(2) a { animation: topItemAnimation 0.5s cubic-bezier(.16,.84,.44,1) 0.4166666666666667s backwards;}
#cssmenu.active li:nth-of-type(3) a { animation: topItemAnimation 0.5s cubic-bezier(.16,.84,.44,1) 0.3333333333333333s backwards;}
#cssmenu.active li:nth-of-type(4) a { animation: topItemAnimation 0.5s cubic-bezier(.16,.84,.44,1) 0.25s backwards;}
#cssmenu.active li:nth-of-type(5) a { animation: topItemAnimation 0.5s cubic-bezier(.16,.84,.44,1) 0.16666666666666666s backwards;}@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { #cssmenu.active li a{animation:none!important;}}

</style>
