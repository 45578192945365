import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/management',
    name: 'Management',
    component: () => import(/* webpackChunkName: "about" */ '../views/SettingsPage.vue'),
    meta: {
      requiresAuth: true,
      is_admin: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutPage.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginPage.vue'),
    meta: {
      guest: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {

      if (localStorage.getItem('jwt') == null) {
          next({
              path: '/login',
              query: {
                  redirect: to.fullPath
              }
          })
      } else {
          let isAuth = localStorage.getItem('auth')
          let timestamp = localStorage.getItem('timestamp')
          if (to.matched.some(record => record.meta.is_admin)) {
              if (isAuth && new Date(timestamp) > Date.now() - 3600000) {
                  next()
              } else {
                  localStorage.clear();
                  next({ name: 'Settings' })
              }
          } else {
              next()
          }
      }
  } else if (to.matched.some(record => record.meta.guest)) {
      if (localStorage.getItem('jwt') == null) {
          next()
      } else {
          next({ name: 'Home' })
      }
  } else {
      next()
  }
})

export default router
